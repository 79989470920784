import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Route } from 'Utilities/Route'
import BackTo from '../../../../Components/Anubis/BackTo'

const AgencyPricing: React.FC<PrivateWizardPageProps> = ({ onwards }) => {
  const [frequency, setFrequency] = useState('monthly')

  useEffect(() => {
    const content = window.document.getElementById('content')
    if (content) {
      content.removeAttribute('id')
      content.setAttribute('id', 'content_replace')
    }
  }, [])

  return <>
    <div className={styles.agencyShopPricing}>
      <div className={styles.togglePricing}>
        <h1>The complete solution for your agency.</h1>
        <h2>How often do you want to pay?</h2>
        <div>
          <button className={frequency === 'monthly' ? styles.active : ''} onClick={() => setFrequency('monthly')}
          >
            Monthly
          </button>
          <button className={frequency === 'yearly' ? styles.active : ''} onClick={() => setFrequency('yearly')}
          >
            Yearly <span><i className="material-icons">sell</i> Get 2 Months Free</span>
          </button>
        </div>
      </div>
      <div className={styles.plans}>
        <div className={styles.plan}>
          <div className={styles.headerIndividual}><h3>Simple Benefits</h3></div>
          <div className={styles.short}>
            The Simple Quoting <br/>and proposals
          </div>
          {frequency === 'monthly' && <div className={styles.price}>
            <span><span className={styles.priceunit}></span>FREE</span>
            <label>&nbsp;</label>
          </div>}
          {frequency === 'yearly' && <div className={styles.price}>
            <span><span className={styles.priceunit}></span>FREE</span>
            <label>&nbsp;</label>
          </div>}
          <div className={styles.select}>
            <button onClick={() => onwards(Promise.resolve())}>
              Get started with Simple Benefits
            </button>
          </div>
          <div className={styles.features}>
            <ul>
              <li>1 User</li>
            </ul>
            <Expandable title="Unlimited Groups">
              Seamlessly quote and print proposals for an unlimited number of groups, ensuring you can serve all your
              clients without restriction.
            </Expandable>
            <Expandable title="Simple Quoting">
              Benefit from access to all 50 states&apos; rates, ensuring you have the most current and competitive
              information at your fingertips.
            </Expandable>
            <Expandable title="Proposals">
              Customized proposals for your clients
            </Expandable>
            <Expandable title="Simple Enrollment">
              Benefit from access to all 50 states&apos; rates, ensuring you have the most current and competitive
              information at your fingertips.
            </Expandable>
          </div>
          <div className={styles.footer}></div>
        </div>
        <div className={styles.plan}>
          <div className={styles.headerIndividual}><h3>Quote to Proposal</h3></div>
          <div className={styles.short}>
            The basics for quick Quoting <br/>and Custom proposals
          </div>
          {frequency === 'monthly' && <div className={styles.price}>
            <span><span className={styles.priceunit}>$</span>99</span>
            <label>PER MONTH</label>
          </div>}
          {frequency === 'yearly' && <div className={styles.price}>
            <span><span className={styles.priceunit}>$</span>999</span>
            <label>PER YEAR</label>
          </div>}
          <div className={styles.select}>
            <button onClick={() => onwards(Promise.resolve())}>
              Continue with Quote to Proposal
            </button>
          </div>
          <div className={styles.features}>
            <ul>
              <li>1 User</li>
            </ul>
            <Expandable title="Unlimited Groups">
              Seamlessly quote and print proposals for an unlimited number of groups, ensuring you can serve all your
              clients without restriction.
            </Expandable>
            <Expandable title="Unlimited Quoting">
              Benefit from access to all 50 states&apos; rates, ensuring you have the most current and competitive
              information
              at your fingertips.
            </Expandable>
            <Expandable title="Proposals">
              Customized proposals for your clients
            </Expandable>
            <Expandable title="Agency Branding">
              Branded shopping experience for your clients
            </Expandable>
          </div>
          <div className={styles.footer}></div>
        </div>
        <div className={styles.planRec}>
          <div className={styles.reccomended}>
            <h4>Recommended</h4>
            <div>
              <div className={styles.headerAgency}><h3>Enhanced</h3></div>
              <div className={styles.short}>
                Everything you need to Quote, Enroll, and Manage your groups
              </div>
              {frequency === 'monthly' && <div className={styles.price}>
                <span><span className={styles.priceunit}>$</span>499</span>
                <label>PER MONTH</label>
              </div>}
              {frequency === 'yearly' && <div className={styles.price}>
                <span><span className={styles.priceunit}>$</span>4,999</span>
                <label>PER YEAR</label>
              </div>}
              <div className={styles.select}>
                <button className={styles.highlight} onClick={() => onwards(Promise.resolve())}>
                  Continue with Agency
                </button>
              </div>
              <div className={styles.features}>
                <ul>
                  <li>Up to 5 Users</li>
                </ul>
                <div className={styles.plus}>
                  <i className="material-icons">arrow_back</i> Everything in Quote to Proposal, plus...
                </div>
                <Expandable title="GA Revenue Sharing">
                  As your client base expands and you achieve specific
                  milestones in employee lives on the platform, you&apos;ll gain the opportunity to share in the General
                  Agents
                  Commissions received by MyHealthily.
                </Expandable>
                <Expandable title="Level-Funding IMQ capabilities">
                  Simplify the data collection process with
                  electronic health questionnaires from six carriers, designed to make submissions quicker and more
                  efficient.
                </Expandable>
                <Expandable title="Employee Class Identification">
                </Expandable>
                <Expandable title="Employer Contribution Strategy">
                  Manage contributions for benefit elections smoothly,
                  ensuring accurate and timely financial management.
                </Expandable>
                <Expandable title="Enrollment">
                  Facilitate a completely electronic enrollment
                  process for employees, enhancing the user experience and efficiency.
                </Expandable>
                <Expandable title="Employee Shop">
                </Expandable>
                <Expandable title="HR Management">
                </Expandable>
                <Expandable title="Ben Admin">
                </Expandable>
              </div>
              <div className={styles.footer}></div>
            </div>
          </div>
        </div>
        {/* <div className={styles.plan}> */}
        {/*   <div className={styles.headerIndividual}><h3>Enterprise</h3></div> */}
        {/*   <div className={styles.short}> */}
        {/*     The basics for quick Quoting <br/>and Custom proposals */}
        {/*   </div> */}
        {/*   <div className={styles.price}> */}
        {/*     <span>Custom</span> */}
        {/*     <label>Per Month</label> */}
        {/*   </div> */}
        {/*   <div className={styles.select}> */}
        {/*     <button onClick={() => onwards(Promise.resolve())}> */}
        {/*       Contact Sales */}
        {/*     </button> */}
        {/*   </div> */}
        {/*   <div className={styles.features}> */}
        {/*     <ul> */}
        {/*       <li>Unlimited User</li> */}
        {/*     </ul> */}
        {/*     <div className={styles.plus}> */}
        {/*       <i className="material-icons">arrow_back</i> Everything in Agency, plus... */}
        {/*     </div> */}
        {/*     <Expandable title="Unlimited Groups"> */}
        {/*       Seamlessly quote and print proposals for an unlimited number of groups, ensuring you can serve all your */}
        {/*       clients without restriction. */}
        {/*     </Expandable> */}
        {/*     <Expandable title="Unlimited Quoting"> */}
        {/*       Benefit from access to all 50 states' rates, ensuring you have the most current and competitive */}
        {/*       information */}
        {/*       at your fingertips. */}
        {/*     </Expandable> */}
        {/*     <Expandable title="Proposals"> */}
        {/*       Customized proposals for your clients */}
        {/*     </Expandable> */}
        {/*     <Expandable title="Agency Branding"> */}
        {/*       Branded shopping experience for your clients */}
        {/*     </Expandable> */}
        {/*   </div> */}
        {/*   <div className={styles.footer}></div> */}
        {/* </div> */}
      </div>
      <div className={styles.footer}>
        <BackTo route={Route.agencyShop_SignUp} analytics="BackTo Sign Up" margin-right="auto" styleType="2021">BackTo
          Sign Up</BackTo>
      </div>
    </div>
    <div className={styles.sectionAgency}>
      <div><h2>Enhanced</h2><p className={styles.top}>Quoting, Proposals, Enrollment, Benefit Administration &amp; So
        Much
        More</p>
      <p className={styles.lead}>
          Our Enhanced Tier is designed to provide up to 5 users with full access to our suite of
          features, each developed to streamline operations and bolster client engagement effectively. This tier
          encompasses the following offerings:</p>
      <ul>
        <li><strong>General Agents Commissions Sharing:</strong> As your client base expands and you achieve specific
            milestones in employee lives on the platform, you&apos;ll gain the opportunity to share in the General Agents
            Commissions received by MyHealthily.
        </li>
        <li><strong>Electronic Employee Health Questionnaires:</strong> Simplify the data collection process with
            electronic health questionnaires from six carriers, designed to make submissions quicker and more efficient.
        </li>
        <li><strong>Comprehensive Electronic Completion and Signature:</strong> Ensure all employer and employee
            paperwork is effortlessly completed and signed electronically, optimizing the enrollment process.
        </li>
        <li><strong>Contributions for Benefit Elections:</strong> Manage contributions for benefit elections smoothly,
            ensuring accurate and timely financial management.
        </li>
        <li><strong>Full Electronic Employee Enrollment:</strong> Facilitate a completely electronic enrollment
            process for employees, enhancing the user experience and efficiency.
        </li>
        <li><strong>Benefits Administration Dashboard:</strong> Oversee all aspects of benefits administration through
            a comprehensive dashboard, providing a clear overview and control.
        </li>
        <li><strong>Agency Branded Employee-Facing Application:</strong> Elevate your agency&apos;s brand with a
            custom-branded application for employees, featuring Lifestyle &amp; Wellness, Incentives, Payroll
            Management, Expense Management, Personal Finance, and Member Engagement.
        </li>
        <li><strong>Additional Users and Locations:</strong> Your agency can add additional users and office locations
            for a fee.
        </li>
      </ul>
      </div>
    </div>
    <div className={styles.section}>
      <div><h2>Quote to Proposal</h2>
        <p className={styles.top}>Unlimited Quoting and Proposals</p>
        <p className={styles.lead}>Our Quote to
          Proposal tier is designed to empower an agency with comprehensive tools for efficient quoting and proposal
          generation. Here&apos;s what you&apos;ll gain access to:</p>
        <ul>
          <li><strong>Unlimited Group Quoting:</strong> Seamlessly quote and print proposals for an unlimited number of
            groups, ensuring you can serve all your clients without restriction.
          </li>
          <li><strong>Nationwide Rate Access:</strong> Benefit from access to all 50 states&apos; rates, ensuring you have
            the most current and competitive information at your fingertips.
          </li>
          <li><strong>ACA Medical Plans:</strong> Access Affordable Care Act (ACA) medical plans from every licensed
            carrier, offering you a wide range of options to meet the diverse needs of your clients.
          </li>
          <li><strong>Non-Underwritten &amp; Medically Underwritten Carriers:</strong> Receive standard rates from 8
            Level-Funded and Medically Underwritten carriers, giving you and your clients more flexibility and choice.
          </li>
          <li><strong>Comprehensive Ancillary Quotes:</strong> Get quotes for Dental, Vision, Short-Term Disability
            (STD), Long-Term Disability (LTD), Life, and Worksite benefits from multiple carriers nationwide.
          </li>
          <li><strong>Additional Users and Locations:</strong> Your agency can add additional users and office locations
            for a fee.
          </li>
        </ul>
      </div>
    </div>
    <div className={styles.section}>
      <div>
        MyHealthily offers 2 tiers of our platform to choose from to meet the needs of agents, brokers, and account
        managers. No contracts, and cancel anytime. Also, you can save more than 15% if you chose to subscribe annually.
        Review the tiers below!
      </div>
    </div>
  </>
}

const Expandable: React.FC<{ title: string }> = ({
  title,
  children
}) => {
  const [expanded, setExpanded] = useState(false)
  return <div className={styles.expandable}>
    <div className={styles.title} onClick={() => setExpanded(!expanded)}>
      <i className="material-icons">{!expanded ? 'chevron_right' : 'expand_more'}</i> {title}
    </div>
    {expanded && <div className={styles.content}>{children}</div>}
  </div>
}

export default AgencyPricing
