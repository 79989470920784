import React from 'react'
import { isProduction } from '../../Utilities/config'

const AddNewClient: React.FC = () => {
  return <a href={isProduction() ? 'https://dashboard.myhealthily.com' : 'https://dashboard.develop.myhealthily.com'}>
    Go to Gen 2 Dashboard
  </a>
}

export default AddNewClient
